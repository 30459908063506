import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Button, Row, Col } from 'react-bootstrap';
import * as dataNames from '../../constants/dataNames';
import * as itemNames from '../../constants/itemNames';
import {
  getData,
  getPaginatedData,
  getUnpaginatedData,
  getItem,
  deleteItem,
  getDataByPost
} from '../../actions/apiActions';
import {setData, unsetData} from '../../actions/dataActions';
import { getReservationSourceLink } from '../../selectors/forms/reservationsFormSelectors';
import { getReservations } from '../../selectors/reservationsSelectors';
import TablePageWrapper from '../common/grid/TablePageWrapper';
import { getPackageColumns } from './common/columnDefinitions';
import { packageTabs } from './common/tabDefinitions';
import ModalWrapper from '../common/ModalWrapper';
import {isLeafPaConfigPackClosedLoopFacility} from '../../selectors/facilitiesSelectors';
import {isFeatureEnabled} from '../../selectors/featureToggles';


export class PackageReservations extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.switchTab = this.switchTab.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.releaseAllReservations = this.releaseAllReservations.bind(this);
    this.releaseReservation = this.releaseReservation.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.hideConfirmModal = this.hideConfirmModal.bind(this);
    this.getReservations = this.getReservations.bind(this);

    this.state = {
      packageId: this.props.packageId,
      itemIds: [],
      packageMetaData: this.props.packageMetaData,
      showLoader: false,
      message: '',
      totalRows: 0,
      tabs: packageTabs.map((tab) => {
        return Object.assign({}, tab, {
          path: tab.path ? tab.path.replace(':id', get(this.props, 'params.id', 0)) : undefined,
          actions: tab.actions.map((action) => {
            return action.func
              ? action
              : {
                ...action,
                func: (e) => {
                  e.target.blur();
                  this.releaseAllReservations();
                }
              };
          })
        });
      }),
      activeTab: get(packageTabs, '0.eventKey')
    };
  }

  componentWillMount() {
    const itemMasterId = this.props.packageMetaData.item_master_id;

    this.props.actions.unsetData(dataNames.itemMasterChildren);
    this.props.actions.unsetData(dataNames.inventoryItems);
    this.props.actions
      .getItem(`/api/item_masters/${itemMasterId}`, itemNames.itemMaster, null, { detailed: 1 })
      .then((itemMaster) => {
        this.setState({ itemMaster: itemMaster });
        if (!get(itemMaster, 'inventory_attributes.is_prepack', 0)) return;
        this.props.actions.getUnpaginatedData('/api/prepack_weights', dataNames.prepackWeights);
      });

    this.props.actions.getDataByPost('/api/items/by_package_ids', { ids: this.props.packageId }).then((data) => {
      const itemIds = data.map((item) => item.id);
      this.setState({
        itemIds,
        columns: this.getColumns(),
        ready: true
      });
      this.getReservations(itemIds);
    });
  }

  /***
   * Adds name and a formatter for buttons
   */
  getColumns() {
    const linkEntityName = (cell, row) => {
      const { entityType, entity_name, entity_id } = row;
      const link = getReservationSourceLink(entityType, entity_id);
      if (!link) return entity_name;
      return (
        <Link to={link} target='_blank'>
          {entity_name}
        </Link>
      );
    };

    return getPackageColumns(this.props.includeFulfilled).map((col) => {
      if (!col.name) {
        return Object.assign({}, col, {
          name: col.dataId ? `reservations.tableColumns.${col.dataId}` : col.name,
          formatter: col.dataId === 'entity_name' ? linkEntityName : undefined
        });
      }
      return Object.assign({}, col, {
        formatter: (cell, row) => { //eslint-disable-line
          return (
            <Button variant='primary' className='btn-block' onClick={(e) => this.releaseReservation(row)}>
              Release
            </Button>
          );
        }
      });
    });
  }

  getReservations(itemIds = false) {
    if (!itemIds) {
      this.props.actions.unsetData(dataNames.reservations);
      this.setState({ totalRows: 0 });
    }

    const item_master_id = get(this.props, 'packageMetaData.item_master_id');
    const package_code = get(this.props, 'packageMetaData.package_id');

    // Get all item reservations
    this.props.actions.getUnpaginatedData('/api/item_reservations', null, null, {item_master_id}).then((item_reservations) => {
      // Filter out items relevant for this package.
      const filtered_item_reservations = item_reservations.filter((item_reservation) => itemIds.includes(item_reservation.item_id));

      // Build list of order ids these reservations are used in (used to check fulfillment)
      const order_ids = filtered_item_reservations.reduce((order_ids, item_reservation) => {
        if (get(item_reservation, 'entity_type') === 'order') {
          order_ids.push(item_reservation.entity_id);
        }
        return order_ids;
      }, []);

      // Only retrieve orders if we have order_ids
      if (order_ids.length) {
        this.props.actions.getData('/api/orders/filter', null, null, {in_ids: order_ids, with_relations: ['products.items']})
          .then((orders) => {
            if (orders.length) {
              // Reduce the relevant order product items for the package
              orders.forEach((order) => {
                // console.log('order id', order.id);
                order.products.forEach((product) => {
                  if (product.item_master_id === item_master_id) {
                    product.items.forEach((item) => {
                      // console.log('order.product.item', item);
                      if (item.package_code === package_code) {
                        // Find item reservation
                        const item_reservation_index = filtered_item_reservations.findIndex((item_reservation) => {
                          return item_reservation.entity_id === order.id && item_reservation.item_id === item.item_id;
                        });
                        const current_fulfilled = get(filtered_item_reservations, item_reservation_index + '.fulfilled', 0);
                        if (item_reservation_index) {
                          const quantity = get(item, 'qty_base', 0);
                          const uom = get(item, 'uom_display', 'GR');
                          const fulfilled = uom === 'GR'
                            ? current_fulfilled + (quantity / 1000000)
                            : current_fulfilled + quantity;
                          filtered_item_reservations[item_reservation_index] = {...filtered_item_reservations[item_reservation_index], fulfilled};
                        }
                      }
                    });
                  }
                });
              });
            }
            this.props.actions.setData(filtered_item_reservations, dataNames.reservations);
          });
      } else {
        // Store data
        this.props.actions.setData(filtered_item_reservations, dataNames.reservations);
      }

      this.setState({ totalRows: filtered_item_reservations.length });
    });
  }

  releaseAllReservations(isConfirmed = false) {
    if (!isConfirmed) return this.showConfirmModal('releaseAllReservations');
    this.props.actions.deleteItem(`/api/item_reservations`, null, null, { item_ids: this.state.itemIds }).then(() => {
      this.getReservations();
    });
  }

  releaseReservation(reservation, isConfirmed) {
    if (!isConfirmed) return this.showConfirmModal('releaseReservation', reservation);
    this.props.actions.deleteItem(`/api/item_reservations/${reservation.id}`).then(() => {
      this.getReservations();
    });
  }

  showConfirmModal(method, data) {
    this.setState({
      showConfirmModal: true,
      showConfirmMessage:
        method.indexOf('All') !== -1
          ? I18n.t('reservations.confirms.releaseAllForPackageId', this.state.packageMetaData)
          : I18n.t('reservations.confirms.releaseOneForPackageId'),
      postConfirmMethod: method,
      postConfirmData: data
    });
  }

  hideConfirmModal(event) {
    if (event.buttonClicked !== 'cancel') {
      if (!this.state.postConfirmData) {
        this[this.state.postConfirmMethod](true); // releaseAllReservations
      } else {
        this[this.state.postConfirmMethod](this.state.postConfirmData, true); // releaseReservation
      }
    }
    this.setState({ showConfirmModal: false, postConfirmMethod: undefined, postConfirmData: undefined });
  }

  switchTab(activeTab) {
    const tab = this.state.tabs.find((tab) => tab.eventKey === activeTab) || this.state.tabs[0];
    this.setState({ activeTab });
    this.props.actions.push(tab.path);
  }

  render() {
    const { reservations, packageMetaData } = this.props;
    return (
      <div>
        <div>
          <Row>
            <Col md={10}>
              <table>
                <tbody>
                  {Object.keys(packageMetaData).map((key, index) => {
                    if (key === 'item_master_id') return null;
                    return (
                      <tr key={index}>
                        <td style={{ paddingRight: '12px' }}>{I18n.t(`reservations.metaDataLabels.${key}`)}:</td>
                        <td>{packageMetaData[key]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
          <div style={{ height: '20px' }} />
        </div>
        <TablePageWrapper
          ref={this.ref}
          className='inventory-reservations'
          settingKey='transfers'
          columns={(this.state.columns || []).filter((c) => {
            return !get(this.state.itemMaster, 'inventory_attributes.is_prepack', 0)
              ? c.dataId !== 'prepackWeight'
              : true;
          })}
          data={Array.isArray(reservations) ? reservations : []}
          activeTab={get(packageTabs, '0.eventKey')}
          tabs={this.state.tabs}
          switchTabx={this.switchTab}
          hideScanSearch={true}
          hideExport={true}
          hideSearch={true}
          bstProps={{
            selectRow: {
              clickToSelect: false,
              hideSelectColumn: true
            }
          }}
        />
        <ModalWrapper
          title={`Confirm`}
          onHide={this.hideConfirmModal}
          showModal={this.state.showConfirmModal}
          headerClass='bg-info-dark'
          dialogClassName='modal-sm'
          cancelButton={{ show: true }}
          okayButton={{ show: true, text: 'Confirm', onClick: this.hideConfirmModal }}
          version={2}
        >
          <div>{this.state.showConfirmMessage}</div>
        </ModalWrapper>
      </div>
    );
  }
}

PackageReservations.propTypes = {
  reservations: PropTypes.array,
  actions: PropTypes.object,
  packageMetaData: PropTypes.object.isRequired,
  packageId: PropTypes.number.isRequired,
  includeFulfilled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    reservations: getReservations(state),
    includeFulfilled: isLeafPaConfigPackClosedLoopFacility(state) && isFeatureEnabled(state)('feature_pa_soft_reservations')
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    push,
    getData,
    getPaginatedData,
    setData,
    unsetData,
    getItem,
    deleteItem,
    getDataByPost,
    getUnpaginatedData
  };
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageReservations);
