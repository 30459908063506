import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { Table } from 'react-bootstrap';
import {FaAsterisk} from 'react-icons/fa';
import NumericInput from '../../common/form/NumericInput';
import AccordionPanel from '../../common/AccordionPanel';
import InternationalQuantityByUomInput from '../../common/form/InternationalQuantityByUomInput';
import {GR} from '../../../constants/uoms';

const SelectedPlants = ({ fields, use_overall, plants, trackPlantsAsGroups }) => {
  const cols = trackPlantsAsGroups ? 4 : 3;
  return (
    <AccordionPanel title='plants.form.selectedPlants' className='selected-plants'>
      <Table className='form-inline' striped hover responsive>
        <thead>
          <tr>
            <th>{I18n.t(trackPlantsAsGroups ? 'plants.table.plantGroupId' : 'plants.table.plantId')}</th>
            <th>{I18n.t('plants.table.strain')}</th>
            <th>{I18n.t('plants.table.batch')}</th>
            {trackPlantsAsGroups && <th>{I18n.t('plants.table.qtyOfPlants')}</th>}
            <th>
              <FaAsterisk className='th-require'/>
              {I18n.t('plants.table.waste')}
            </th>
            {trackPlantsAsGroups && (
              <th>
                {!use_overall && (
                    <FaAsterisk className='th-require'/>
                  )
                }
                {I18n.t('plants.table.plantsDestroyed')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr className='overall-values' hidden={!use_overall}>
            <td colSpan={cols}>
              <label>{I18n.t('plants.form.overallValues')}</label>
            </td>
            <td>
              <InternationalQuantityByUomInput
                name='destroyed_weight'
                disabled={!use_overall}
                placeholder={I18n.t('common.form.numericPlaceholder')}
                uom={GR}
              />
            </td>
            {trackPlantsAsGroups && <td></td>}
          </tr>
          {fields.map((plant, index) => (
            <tr key={index}>
              <td>{plants[index].plant_id}</td>
              <td>{plants[index].strain_name}</td>
              <td>{plants[index].batch_name}</td>
              {trackPlantsAsGroups && <td>{plants[index].qty}</td>}
              <td>
                <InternationalQuantityByUomInput
                  name={`${plant}.destroyed_weight`}
                  disabled={use_overall}
                  placeholder={I18n.t('common.form.numericPlaceholder')}
                  uom={GR}
                />
              </td>
              {trackPlantsAsGroups && (
                <td>
                  <Field
                    name={`${plant}.plants_destroyed`}
                    component={NumericInput}
                    props={{
                      placeholder: I18n.t('common.form.numericPlaceholder'),
                      max: 99999,
                      min: 0,
                      step: 'any'
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </AccordionPanel>
  );
};

SelectedPlants.propTypes = {
  plants: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  use_overall: PropTypes.bool,
  trackPlantsAsGroups: PropTypes.bool
};

export default SelectedPlants;
